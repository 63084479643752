import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import favi from '../images/ikony/favi.png'


const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation()
  const site = [{
    "defaltTitle": 'Odra Studio',
    "titleTemplate": "%s · Projektujemy Wyjątkowe Marki",
    "defaultDescription":
      "Odra Studio. Tworzymy angażujące doświadczenia i wartościowe interakcje. Projektujemy wyjątkowe marki.",
    "siteUrl": "https://www.odrastudio.com", // No trailing slash allowed!
    "defaultImage": "/images/vp1.jpg", // Path to your image you placed in the 'static' folder
  },]

  const defaultTitle = site.defaultTitle
  const titleTemplate = "%s · Projektujemy Wyjątkowe Marki"
  const defaultDescription = site.defaultDescription
  const siteUrl = "https://www.odrastudio.com"
  const defaultImage = site.defaultImage
  const seo = {
    title: title,
    description: description,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
  }
 
  return (
    <React.Fragment>
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-DG43MSY5YC"></script>
 
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="shortcut icon" sizes="16x16 24x24 32x32 48x48 64x64" href={favi} />

   
      <link rel="apple-touch-icon" sizes="57x57" href={favi} />
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={favi} />
      <link rel="apple-touch-icon" sizes="72x72" href={favi} />
      <link rel="apple-touch-icon" sizes="114x114" href={favi} />
      <link rel="apple-touch-icon" sizes="120x120" href={favi} />
      <link rel="apple-touch-icon" sizes="144x144" href={favi} />
      <link rel="apple-touch-icon" sizes="152x152" href={favi} />

    
      <meta name="application-name" content="Odra Studio" />
      <meta name="msapplication-TileImage" content={favi} />
      <meta name="msapplication-TileColor" content="#fedd00" />

  
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
    <head title={seo.title} titleTemplate={titleTemplate}>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-DG43MSY5YC"></script>

    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
    <meta name="description" content={seo.description} />
    <meta name="image" content={seo.image} />
    <link rel="shortcut icon" sizes="16x16 24x24 32x32 48x48 64x64" href={favi} />

   
<link rel="apple-touch-icon" sizes="57x57" href={favi} />
<link rel="apple-touch-icon-precomposed" sizes="57x57" href={favi} />
<link rel="apple-touch-icon" sizes="72x72" href={favi} />
<link rel="apple-touch-icon" sizes="114x114" href={favi} />
<link rel="apple-touch-icon" sizes="120x120" href={favi} />
<link rel="apple-touch-icon" sizes="144x144" href={favi} />
<link rel="apple-touch-icon" sizes="152x152" href={favi} />


<meta name="application-name" content="Odra Studio" />
<meta name="msapplication-TileImage" content={favi} />
<meta name="msapplication-TileColor" content="#fedd00" />


<meta content="yes" name="apple-mobile-web-app-capable" />
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
    {seo.url && <meta property="og:url" content={seo.url} />}
    {seo.title && <meta property="og:title" content={seo.title} />}
    {seo.description && (
      <meta property="og:description" content={seo.description} />
    )}
    {seo.image && <meta property="og:image" content={seo.image} />}
    <meta name="twitter:card" content="summary_large_image" />
    {seo.title && <meta name="twitter:title" content={seo.title} />}
    {seo.description && (
      <meta name="twitter:description" content={seo.description} />
    )}
    {seo.image && <meta name="twitter:image" content={seo.image} />}
  </head>
  </React.Fragment>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

