import React, {createContext, Component} from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

export const PlatonContext = createContext()

class ThemeContextProvider extends Component {
  state = {
    ocenaProjektu: false,
    zobaczUslugi: true,
  }

  render() {
    return(
      <PlatonContext.Provider value={{...this.state}}>
        {this.props.children}
      </PlatonContext.Provider>
    )
  }

}


export default ThemeContextProvider