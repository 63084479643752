

import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from 'gatsby-plugin-transition-link'
import dummy from '../images/dummy_bg@2x.png'
import Bread from '../components/bread'
import { useInView } from 'react-intersection-observer'
import logo from '../images/branding/logo.svg'
import vp from '../images/branding/vp_header.svg'
import mail from '../images/formularz/kontakt_ikona.svg'
import mobile from './mobile.css'
import header_style from './header.css'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import skontaktuj from "../images/ikony/skontaktuj.svg"
import skontaktuj_czat from "../images/ikony/skontaktuj_czat.svg"
import Formularz from './formularz/formularz'
import { ParallaxProvider, withController, useController } from 'react-scroll-parallax'
import { motion, AnimatePresence, Frame } from "framer-motion"
import Home from './home.js'
import telefon from '../images/ikony/telefon.svg'
import fb from '../images/ikony/fb.svg'
import ig from '../images/ikony/ig.svg'
import CookieConsent from "react-cookie-consent";
import platon from '../images/platon/platon.png'
import window from 'global/window';
import style from "./layout.css"
import zamknij from '../images/ikony/zamknij.svg'

import fonts from "../fonts/fonts.css"

import ThemeContextProvider from "./PlatonKontekst"

import { ThemeContext } from './PlatonKontekst'
import CacheProvider from './provider'
import Marquee from "react-fast-marquee";

const Layout = ({ children, location }) => {

  const [znacznikBread, breadZmiana] = useInView({
    initialInView: true,
  })

  const contextType = ThemeContext

  console.log(ThemeContext);
  
  const wrapper = useRef(null)
  const szer = useRef()

  useScrollPosition(
    ({ prevPos, currPos }) => {
    console.log(currPos.x)
    console.log(currPos.y)
    },
    {wrapper},
    false,
    300,
  )



  const [stanOkna, ustawOkno] = useState(false);

  const [stanMenu, ustawMenu] = useState(false);



  const [czyFormularz, ustawFormularz] = useState(false);





  const okno = useRef();
  const constraintsRef = useRef(null)

  

  



  useEffect(() => {
    szer.current = okno.current.clientWidth;   

  }, [])






  useLayoutEffect(() => {
    ustawMenu(false)
    if((location.pathname.includes("/projekty/")) || (location.pathname.includes("/uslugi/"))) {
      okno.current.scrollTo({
        top: 0,
        left: 0,
      });
    }
    else {
      okno.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    
    if (location.state !== null && location.state.pageState !== null || location.pathname == "/" || location.pathname == "/formularz") {
      if (location.pathname == "/" ){
        ustawOkno(false)
      }
      else if (location.pathname == "/formularz") {
        ustawOkno(true)
      }
      else if (location.state.pageState == "START") {
        ustawOkno(false)
      }
      else {
        ustawOkno(true)
      }
    }
    else {
      ustawOkno(true)
    }


    if(location.pathname == "/formularz") {
      ustawFormularz(true)
    }
    else {
      ustawFormularz(false)
    }
  }, [location.pathname])

  function switcherMenu() {
    ustawMenu(!stanMenu);
    console.log(stanMenu);
  }





  const data = useStaticQuery(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)





  return ( <>
 

<ThemeContextProvider>
    <AnimatePresence>
      <motion.div
       initial={{ x: 500, opacity: 0 }}
       animate={{ x: 0, opacity: 1 }}
       style={{transition: '0.6s cubic-bezier(0.22, 1, 0.36, 1)'}}
      >
    <CookieConsent
  location="bottom"
  buttonText="Akceptuję"
  containerClasses="cookies-popup"
  debug={false}
  cookieName="myAwesomeCookieName2"
  style={{  zIndex: '10000000000' }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
><span className="przycisk">używamy ciastek</span><br />
  <p>Ta strona wykorzystuje pliki cookie. Jeżeli wyrażasz zgodę na zapisywanie informacji zawartej w cookies kliknij poniższy przycisk. Jeśli nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz zmienić w swojej przeglądarce.</p>
  
</CookieConsent>
</motion.div>
      <motion.div
        id="layout-grid"
        ref={constraintsRef}
        className={location.pathname === "/formularz/" || location.pathname === "/formularz" ? "formularz-layout" : ""}>
        <div
          
          className={`platon-wrapper ${czyFormularz ? "platon-wypierdalaj" : ""} ${stanOkna ? "platon-podstrona" : ""}`}
          id="platon"
        >
          <motion.div
            drag
            layout 
            dragConstraints={constraintsRef} >
            <img draggable="false" src={platon} />
            <div className="platon-notyfikacja-wrapper">
              <p><span className="notyfikacja-liczba">1</span>
              <TransitionLink
                state={{ title: 'USŁUGI', past: location.pathname }}

                to="/uslugi"

                exit={{
                  length: 1,
                  state: { x: -szer.current},
                }}
                entry={{
                  length: 1,             
                  state:  location.pathname === "/"  ? {x: 0} :  {x: (szer.current),  past: location.pathname, len: szer.current},
                }}

              >
              <span className="notyfikacja-tekst">Hej!<br/><u>Zobacz jakie oferujemy usługi</u>  👀</span></TransitionLink></p>
              

            </div>

          </motion.div>

        </div>
        <aside style={{position: 'relative'}}>
        <TransitionLink style={{height: '100%', width: '100%', position: 'absolute', zIndex: '0'}} 
        state={{ pageState: 'START' }} to="/" >

        </TransitionLink>
          <TransitionLink
            
            to="/formularz"
            activeClassName="rekomendacje-aktywne"
            style={{
              zIndex: '10000',
              display: 'block',

          }}
            onClick={{

            }}>
            <div className="kontakt-sidebar">
              <div className="rekomendacje-button" style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                border: "1px solid #000",
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <img
                  src={skontaktuj}
                  alt="Skonaktuj się z nami!"
                  id="rekomendacje"
                  className="kontakt-sidebar-tekst" />
                <img src={skontaktuj_czat}
                  style={{
                    position: 'absolute',
                  }} />
              </div>
            </div>
          </TransitionLink>

        </aside>


        <header >
          <TransitionLink state={{ pageState: 'START' }} to="/" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }} id="logo">
            <img src={logo} alt="Logo firmy Odra Studio / Wróc do strony głównej" />
          </TransitionLink>


          <div id="header-vp" style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}>
            <Marquee gradient={false} style={{}}>
              <img src={vp} alt="Hasło/Value Proposition firmy Odra Studio" />

              <img src={vp} alt="Hasło/Value Proposition firmy Odra Studio" />

              <img src={vp} alt="Hasło/Value Proposition firmy Odra Studio" />

              <img src={vp} alt="Hasło/Value Proposition firmy Odra Studio" />
            

            </Marquee>
          </div>
          <div className={stanMenu ? "menu-otwarte" : ""} style={{display: 'flex', justifyContent: 'center', flexGrow: '3'}}>
            <ul style={{
              padding: '18px 41px 18px 40px',
              listStyleType: 'none',
              listStyle: 'none',
            }} id="podstrony">

              <div id="menu-zamknij" onClick={switcherMenu}>
                <img src={zamknij}/>
              </div>
           
              
                <TransitionLink
                  state={{ pageState: 'START' }}
                  activeClassName="podstrona-aktywna"
                  onClick={() => (ustawOkno(false))}
                  to="/"
                  
                >
                  <li className="przycisk">START</li>
                </TransitionLink>
                
              <TransitionLink
                state={{ title: 'USŁUGI', past: location.pathname }}
                activeClassName="podstrona-aktywna"
                to="/uslugi"
                partiallyActive={true}
                exit={{
                  length: 1,
                  state: { x: -szer.current},
                }}
                entry={{
                  length: 1,             
                  state:  location.pathname === "/"  ? {x: 0} :  {x: (szer.current),  past: location.pathname, len: szer.current},
                }}

              >
                <li className="przycisk">OFERTA</li>
              </TransitionLink>
              <TransitionLink
                partiallyActive={true}
                state={{ title: 'PROJEKTY', past: location.pathname }}
                activeClassName="podstrona-aktywna"
                to="/projekty"
                exit={{
                  length: 1,
                  state: { x: -szer.current},
                }}
                entry={{
                  length: 1,             
                  state:  location.pathname === "/"  ? {x: 0} :  {x: szer.current,  past: location.pathname, len: szer.current},
                }}

              >
                <li className="przycisk">PROJEKTY</li>
              </TransitionLink>
              <TransitionLink
                state={{ title: 'O NAS', past: location.pathname }}
                activeClassName="podstrona-aktywna"
                partiallyActive={true}
                to="/o-nas"
                exit={{
                  length: 1,
                  state: { x: -szer.current},
                }}
                entry={{
                  length: 1,             
                  state:  location.pathname === "/"  ? {x: 0} :  {x: szer.current,  past: location.pathname, len: szer.current},
                }}
              >
                <li className="przycisk">O NAS</li>
              </TransitionLink>
              <TransitionLink
                state={{ title: 'REKOMENDACJE' }}
                activeClassName="podstrona-aktywna"
                partiallyActive={true}
                to="/rekomendacje"
                exit={{
                  length: 1,
                  state: { x: -szer.current},
                }}
                entry={{
                  length: 1,             
                  state:  location.pathname === "/"  ? {x: 0} :  {x: szer.current,  past: location.pathname, len: szer.current},
                }}
              >
                <li className="przycisk">REKOMENDACJE</li>
              </TransitionLink>
              <TransitionLink
                state={{ title: 'KONTAKT' }}
                activeClassName="podstrona-aktywna"
                partiallyActive={true}
                to="/formularz"
             

              >
                <li className="przycisk">KONTAKT</li>
              </TransitionLink>


              

              <div className="menu-info">
                <div>
                <img src={telefon} />
                <p><a href="mailto: kontakt@odrastudio.com">kontakt@odrastudio.com</a>
              <a href="tel: +48 509 550 40">+48 509 550 409</a>
              Łokietka 7/4, 50-243 Wrocław<br />
              Pn-Pt: 10.00-18.00</p>
              </div>

              <div className="menu-social">
                <a target="_blank" href="https://www.facebook.com/odrastudio/"><p style={{marginRight: '10px'}}>FB</p></a>
                <a target="_blank" href="https://www.instagram.com/odrastudio/"><p>IG</p></a>
              </div>

              
              </div>

              
            </ul>

            

          </div>

          <div class="menu-social-ikony">
                <a href="https://www.facebook.com/odrastudio" class="przycisk" target="_blank"> 
                  FB
                </a>
                <a  class="przycisk" href="https://www.instagram.com/odrastudio/" target="_blank">
                  IG
                </a>
                <a  class="przycisk" href="https://www.behance.net/odra" target="_blank">
                  BH
                </a>
                <a  class="przycisk" href="https://www.linkedin.com/company/odra-studio/?originalSubdomain=pl" target="_blank">
                  IN
                </a>
              </div>  

          <div className={stanMenu ? "menu-mobile menu-active" : "menu-mobile"} onClick={switcherMenu}>
            <span className="przycisk">MENU</span>
          </div>




        </header>

        <ParallaxProvider scrollContainer={okno.current}>
          <div id="okno-scroller" ref={okno} className={stanOkna ? "podstrona okno-aktywne" : "podstrona"}>
            <div
              ref={znacznikBread}
              style={{
                position: 'absolute',
                top: '20px',
                left: '0px',
                width: '1px',
                height: '1px',
              }}></div>

            

           
            <motion.main
              className={breadZmiana ? '' : 'bread-aktywny'}
              key={location.pathname}
              ref={wrapper}
              key="wrapper_main"
          
            >


              {children}

        

              <div className="wrapper-test"></div>
            </motion.main>
      


          </div>


          </ParallaxProvider>

    

     












              <div style={{height: '100%', position: 'relative', gridArea: 'glowna', width: '100vw', overflow: 'hidden'}} className={stanOkna ? "animacja-chowaj" : ""} >
              <Home  />
              </div>







      </motion.div>
    </AnimatePresence>
    </ThemeContextProvider>

    </>




  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(Layout)





