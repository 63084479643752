import React from 'react'
import { render } from 'react-dom'
import Seo from './seo.js'

import TransitionLink from 'gatsby-plugin-transition-link'
import bg from '../images/home/background@2x.jpg'

import dol from '../images/home/typo_dol@2x.png'
import gora from '../images/home/typo_gora@2x.png'

import dol_mobile from '../images/home/dol_mobile.svg'
import gora_mobile from '../images/home/gora_mobile.svg'
import linia from '../images/home/linia.svg'
import home from '../images/seo/home.jpg'

import wideo from './odra_full4.mp4'





class Home extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.dummy = React.createRef()
        this.info = React.createRef()
        this.animacja = React.createRef()
        this.state = {
            oferta: true,
            mobile: false,
        }
        this.reset = 0;
       
    }

    

    componentDidMount() {
        this.domRect = this.dummy.current.getBoundingClientRect()
        this.diff = this.domRect.height / 5
        this.finalHeight = this.domRect.height - this.diff
        this.pos = 0;
        this.percent = 0;

        if(this.domRect.width < 1025) {
            this.setState({
                mobile: true,
            })
        }


        





    }


   onScroll = ()=> {  
        // const scrollTop = this.myRef.current.scrollTop
        // this.percent = scrollTop / this.finalHeight;
        // this.pos = this.percent * 24 
        // var rounded = Math.round(this.pos * 10) / 10
        // this.animacja.current.currentTime = rounded


       
        // console.log(rounded);
        // if(rounded > 26) {
        //     this.myRef.current.scrollTop = 0
        
        // }


   }

    


 
   
    
    
 

     


    render() {

        
        const isMobile = this.state.mobile
        let kontakt
        if(isMobile) {
            kontakt =   <TransitionLink
            
            to="/formularz"
            activeClassName="rekomendacje-aktywne"
            style={{
              zIndex: '10000',
              display: 'block',

          }}
            onClick={{

            }}><svg className="kontakt-mobile" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 38 38">
            <g id="Group_9407" data-name="Group 9407" transform="translate(-47 -47)">
                <g id="Ellipse_210" data-name="Ellipse 210" transform="translate(47 47)" fill="#fff" stroke="#000" stroke-width="0.9">
                <circle cx="19" cy="19" r="19" stroke="none"/>
                <circle cx="19" cy="19" r="18.5" fill="none"/>
                </g>
                <g id="Group_8916" data-name="Group 8916" transform="translate(55.811 57.193)">
                <g id="Group_8894" data-name="Group 8894">
                    <path id="Path_12844" data-name="Path 12844" d="M295.2,481.267h-9.706a3.813,3.813,0,0,0-3.813,3.813h0a3.813,3.813,0,0,0,3.813,3.813h.038l2.469,2.23v-2.23h7.2a3.813,3.813,0,0,0,3.813-3.813h0A3.813,3.813,0,0,0,295.2,481.267Z" transform="translate(-281.682 -481.267)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                    <path id="Path_12845" data-name="Path 12845" d="M350.661,498.225a.5.5,0,1,1-.5-.5A.5.5,0,0,1,350.661,498.225Z" transform="translate(-342.644 -496.026)"/>
                    <path id="Path_12846" data-name="Path 12846" d="M375.6,498.225a.5.5,0,1,1-.5-.5A.5.5,0,0,1,375.6,498.225Z" transform="translate(-365.28 -496.026)"/>
                    <path id="Path_12847" data-name="Path 12847" d="M351.185,516.658a1.881,1.881,0,0,1-3.762,0" transform="translate(-340.638 -513.005)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
                </g>
                <g id="Group_8895" data-name="Group 8895" transform="translate(8.236 9.493)">
                    <path id="Path_12844-2" data-name="Path 12844" d="M284.46,481.267h7.071a2.778,2.778,0,0,1,2.778,2.778h0a2.778,2.778,0,0,1-2.778,2.778H291.5l-1.8,1.625v-1.625H284.46a2.778,2.778,0,0,1-2.778-2.778h0A2.778,2.778,0,0,1,284.46,481.267Z" transform="translate(-281.682 -481.267)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                </g>
                </g>
            </g>
            </svg></TransitionLink> 
        }
    
        
    
       
        
        return (
            <>
            <Seo title="ODRA STUDIO" image={home} description="Odra Studio. Tworzymy angażujące doświadczenia i wartościowe interakcje. Projektujemy wyjątkowe marki." />
            {kontakt}
            <div className={this.state.oferta ? 'home-info oferta-aktywna' : "home-info"} ref={this.info} >
            
                <TransitionLink
                state={{ title: 'USŁUGI'}}
                activeClassName="podstrona-aktywna"
                to="/uslugi"
                partiallyActive={true}
                exit={{
                  length: 1,
                }}
                entry={{
                  length: 1,             
                }}

              >
                <span className="przycisk button-goto">
                    ZOBACZ OFERTĘ
                </span>
                </TransitionLink>
                
            </div>
           
           <div
                className="animacja-wrapper"
                ref={this.myRef}
                style={{zIndex: '-1'}}
                >
                    <div ref={this.dummy} class="dummy-scroll">
                        
                    </div>
                    <div>
                <video
                    ref={this.animacja}
                    tabIndex="0"
                    autobuffer="autobuffer"
                    preload="preload"
                    style={{ width: '100%', objectFit: 'contain' }}
                    playsInline
                    loop
                    autoPlay
                    muted
                >
                    <source type="video/mp4" src={wideo} />
                    {/* <source style={{zIndex: '-1'}} type="video/mp4" src={wideo_alt} /> */}
                
                </video>

                </div>
                </div>
       
        <div
        
        className="home-wrapper">
            <div>
            <div style={{position: "relative"}}>
            <img className="typo-gora" src={this.state.mobile ? gora_mobile : gora} />
            <img className="linia-home" src={linia} />
            
            
            </div>

            <img className="typo-dol" src={this.state.mobile ? dol_mobile : dol} />
            </div>

               

               
        
                    
        </div>       
        </>
        )
    }

}

export default Home