import React from 'react'
import zamknij from '../images/ikony/zamknij.svg'
import paluch from '../images/ikony/paluch.svg'
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from 'gatsby-plugin-transition-link'
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { motion } from "framer-motion"
// const smtitle = props.smtitle
//
// function smtitle(smtitle) {
//   if(props.smtitle) {
//     render(
//       <h6>{props.smtitle}</h6>
//     )
//   }
// }


class Bread extends React.Component {
  constructor(props) {
    super(props);
    this.stanOkna = this.stanOkna.bind(this);
  }

  stanOkna(e) {

  }


 
  

  render() {
   

    function LinkPrzycisk(props){

    if(props.tekst) {
      return(
        <a href={props.link} className="bread-link">
   
            <img src={paluch} />
            <span className="przycisk">{props.tekst}</span>
       
        </a>
      )
    }
    else{ 
      return(<></>)
    }
        
    

     
    }
  


    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -100 },
    }
    const item2 = {
      visible: { opacity: 1, x: 0 },
      hidden: { opacity: 0, x: 500 },
    }
    return (
      <div className='bread-crumb'>
        <TransitionLink className="link-box" to="/" state={{ pageState: 'START' }}>
          <div className="okno-zamknij">
          <img
            onClick={this.stanOkna} 
            src={zamknij} 
              alt="Zamknij podstronę" />
          </div>
        </TransitionLink>
        <span 
        className="breadcrumb">{this.props.title}</span>

        <div className="bread-dodatkowe">
        <LinkPrzycisk  link={this.props.link1} tekst={this.props.tekst1} />
        <LinkPrzycisk  link={this.props.link2}  tekst={this.props.tekst2} />
        </div>
 
     
        <div className="bread-linia"></div>
      </div>
    )
  }
}


export default Bread