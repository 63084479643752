// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formularz-js": () => import("./../../../src/pages/formularz.js" /* webpackChunkName: "component---src-pages-formularz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projekty-beefit-js": () => import("./../../../src/pages/projekty/beefit.js" /* webpackChunkName: "component---src-pages-projekty-beefit-js" */),
  "component---src-pages-projekty-betard-js": () => import("./../../../src/pages/projekty/betard.js" /* webpackChunkName: "component---src-pages-projekty-betard-js" */),
  "component---src-pages-projekty-dummy-projekt-js": () => import("./../../../src/pages/projekty/dummy_projekt.js" /* webpackChunkName: "component---src-pages-projekty-dummy-projekt-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-projekty-khole-js": () => import("./../../../src/pages/projekty/khole.js" /* webpackChunkName: "component---src-pages-projekty-khole-js" */),
  "component---src-pages-projekty-kogym-js": () => import("./../../../src/pages/projekty/kogym.js" /* webpackChunkName: "component---src-pages-projekty-kogym-js" */),
  "component---src-pages-projekty-lato-js": () => import("./../../../src/pages/projekty/lato.js" /* webpackChunkName: "component---src-pages-projekty-lato-js" */),
  "component---src-pages-projekty-magic-js": () => import("./../../../src/pages/projekty/magic.js" /* webpackChunkName: "component---src-pages-projekty-magic-js" */),
  "component---src-pages-projekty-rite-js": () => import("./../../../src/pages/projekty/rite.js" /* webpackChunkName: "component---src-pages-projekty-rite-js" */),
  "component---src-pages-projekty-speedway-js": () => import("./../../../src/pages/projekty/speedway.js" /* webpackChunkName: "component---src-pages-projekty-speedway-js" */),
  "component---src-pages-projekty-volley-js": () => import("./../../../src/pages/projekty/volley.js" /* webpackChunkName: "component---src-pages-projekty-volley-js" */),
  "component---src-pages-rekomendacje-js": () => import("./../../../src/pages/rekomendacje.js" /* webpackChunkName: "component---src-pages-rekomendacje-js" */),
  "component---src-pages-uslugi-branding-js": () => import("./../../../src/pages/uslugi/branding.js" /* webpackChunkName: "component---src-pages-uslugi-branding-js" */),
  "component---src-pages-uslugi-design-js": () => import("./../../../src/pages/uslugi/design.js" /* webpackChunkName: "component---src-pages-uslugi-design-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-uslugi-media-js": () => import("./../../../src/pages/uslugi/media.js" /* webpackChunkName: "component---src-pages-uslugi-media-js" */)
}

