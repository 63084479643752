import React from 'react'
import paluch from '../../images/ikony/paluch_yellow.svg'
import TransitionLink from 'gatsby-plugin-transition-link'

const ButtonCzarny = React.forwardRef((props, ref) => (

         <TransitionLink 
              to={props.url}
              exit={{
                length: 1,
                delay: 0,
              }}
              entry={{
                appearAfter: 0,
              }}>
    <button ref={ref} className="button-czarny">
        <img src={paluch} alt="Przycisk" />
        <span className="przycisk">{props.text}</span>
    </button>
    </TransitionLink>
))



export default ButtonCzarny