import React from 'react'
import stopka from '../../images/formularz/dlon.png'
import ButtonCzarny from '../buttons/button_czarny'
import telefon from '../../images/ikony/telefon.svg'
import { motion, useElementScroll } from "framer-motion"
const formularz = React.createRef();

const Formularz = React.forwardRef((props, ref) => (
  <div ref={ref} className="formularz-wrapper">
  <div className="formularz-cta">
  <h6>Kontakt</h6>
  <h3>Cześć! Masz do nas<br />sprawę? <span><span className="tekst-animacja"><svg className="tekst-animacja-zaznaczenie" xmlns="http://www.w3.org/2000/svg" width="254.503" height="76.997" viewBox="0 0 254.503 76.997">
            <g transform="translate(0.513 0.521)">
              <motion.path
                d="M-15652.36,2837.4s-17.459,22.146-18.82,37.27,16.15,34.568,71.4,34.568,120.627,1.35,141.445-8.642,49.006-27.277,38.121-46.992-24.7-19.715-72.947-20.255-163.43,3.511-176.076,20.255"
                transform="translate(15671.254 -2833.302)"
                fill="none"
                stroke="#000"
                stroke-width="1"
                initial={{ pathLength: 0, opacity: 0, }}
                animate={{ pathLength: 1, opacity: 1 }}
                transition={{ duration: 2 }} />
            </g></svg><h3>Napisz</h3></span></span></h3>
    <ButtonCzarny ref={formularz} url="/formularz" text="Porozmawiajmy"/>
  </div>
  
  <div className="formularz-info-stopka">
    <img src={telefon} />
    <p>kontakt@odrastudio.com<br/>
       +48 509 550 409<br/>
       Łokietka 7/4, 50-243 Wrocław<br/>
       Pn-Pt: 10.00-18.00 </p><br/>
    <p><a target="_blank" href="https://www.facebook.com/odrastudio/" style={{marginRight: '10px'}}>FB</a><a target="_blank" href="https://www.instagram.com/odrastudio/">IG</a></p>
  </div>
  <img src={stopka} />
</div>
))

export default Formularz


